import { store } from '~/pages/heineken_template/_private/store';
import { css } from '@emotion/react';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { Daddy960_Footer } from '../daddy960_opkevin/component/daddy960_Footer';
import { Daddy960_stocknerve_SidePane1 } from './daddy960_stocknerve_SidePane1';
import { Daddy960_stocknerve_SidePane2 } from './daddy960_stocknerve_SidePane2';
import { daddy960_stocknerve_initStyling } from './daddy960_stocknerve_initStyling';
import { daddy960_stocknerve_initStrategies } from './daddy960_stocknerve_initStrategies';
import { fr_me } from '../heineken_template/_fr/fr_me';
import { Daddy960_TopBar } from '../daddy960_opkevin/component/daddy960_TopBar';
import { Daddy960LoginView } from '../daddy960_opkevin/component/Daddy960LoginView';
import { Daddy960_stocknerve_SidePane3 } from './daddy960_stocknerve_SidePane3';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import { meCheckHandlerCreateByProductNamesWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Fr_WithDrawerCloseButton from '~/pages/heineken_template/_fr/fr_WithDrawerCloseButton';
export const daddy960_stocknerve_init = {
    global(templateProps) {
        daddy960_stocknerve_initStyling(templateProps);
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
    },
    indexPage(templateProps) {
        daddy960_stocknerve_init.global(templateProps);
        daddy960_stocknerve_initStrategies();
        templateProps.hooks.add(fr_me.useInstall);
        // templateProps.permissions.pageview =
        //   fr_me.toTemplate.permissions.hasPermissionOrHasLoginBeforeServerDate({
        //     agentProduct: AgentProduct['daddy960@web_stocknerve'],
        //     date: dayAPI('2023/04/01 06:00'),
        //   })
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
            'web_stocknerve',
            'web_stocknerve_pro',
        ]);
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'CDF-1',
            interval: '1',
            enableVolumeIndicator: false,
            disabledHeaderWidget: false,
            disabledLeftToolbar: false,
            disabledPaneMenu: true,
            disabledTimeframesToolbar: false,
            disabledHeaderChartType: false,
            disabledHeaderSaveload: false,
            disabledHeaderCompare: false,
            overrides: {
                ...store.charting.darkOverrides,
                'paneProperties.legendProperties.showLegend': false,
                'mainSeriesProperties.showPriceLine': true,
                'scalesProperties.showSeriesLastValue': true,
            },
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px calc(100vh - 48px) 1fr;
      grid-template-columns: 336px 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Drawer2 Chart'
        'Row2 Row2 Row2';

      ${createIPadCss(css `
        grid-template-rows: 48px calc(100% - 56px) 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px calc(100% - 56px) 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row2 Row2 Row2';
      `)}
    `;
        // Init components
        templateProps.layout.Row1 = (<Daddy960_TopBar leftBurger={true} product={'stocknerve'} clientId={[
                { label: '自用樣板', value: 'daddy960' },
                { label: '導航樣板', value: 'daddy960_hang' },
            ]}/>);
        templateProps.layout.Row2 = Daddy960_Footer;
        templateProps.layout.Drawer1 = (<Fr_WithDrawerCloseButton.Display right Button={<BsChevronRight css={css `
              #__html {
                position: relative;
                z-index: 100;
              }
              color: #ffff00;
              background-color: #56565644;
              border-radius: 5px;
              height: 64px;
              width: 28px;
              font-size: 24px;
            `}/>}>
        <Daddy960_stocknerve_SidePane2 />
      </Fr_WithDrawerCloseButton.Display>);
        templateProps.layout.Drawer2 = (<Fr_WithDrawerCloseButton.Display left Button={<BsChevronLeft css={css `
              #__html {
                position: relative;
                z-index: 100;
              }
              color: #ffff00;
              background-color: #56565644;
              border-radius: 5px;
              height: 64px;
              width: 28px;
              font-size: 24px;
            `}/>}>
        <Daddy960_stocknerve_SidePane1 />
      </Fr_WithDrawerCloseButton.Display>);
        templateProps.layout.login = (<Daddy960LoginView product='stocknerve' faviconUrl='stocknerve/favicon.png' lineLink='https://page.line.me/?accountId=730hvfcs'/>);
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['stocknerve/shareholding-distribution/index.page'](templateProps) {
        daddy960_stocknerve_init.global(templateProps);
        daddy960_stocknerve_initStrategies();
        templateProps.hooks.add(fr_me.useInstall);
        // templateProps.permissions.pageview =
        //   fr_me.toTemplate.permissions.hasPermissionOrHasLoginBeforeServerDate({
        //     agentProduct: AgentProduct['daddy960@web_stocknerve'],
        //     date: dayAPI('2023/04/01 06:00'),
        //   })
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
            'web_stocknerve',
            'web_stocknerve_pro',
        ]);
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'CDF-1',
            interval: '1',
            enableVolumeIndicator: false,
            disabledHeaderWidget: false,
            disabledLeftToolbar: false,
            disabledPaneMenu: true,
            disabledTimeframesToolbar: false,
            disabledHeaderChartType: false,
            disabledHeaderSaveload: false,
            disabledHeaderCompare: false,
            overrides: {
                ...store.charting.darkOverrides,
                'paneProperties.legendProperties.showLegend': false,
                'mainSeriesProperties.showPriceLine': true,
                'scalesProperties.showSeriesLastValue': true,
            },
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px calc(100vh - 48px) 1fr;
      grid-template-columns: 336px 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Drawer2 Chart'
        'Row2 Row2 Row2';

      ${createIPadCss(css `
        grid-template-rows: 48px calc(100% - 56px) 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px calc(100% - 56px) 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row2 Row2 Row2';
      `)}
    `;
        // Init components
        templateProps.layout.Row1 = (<Daddy960_TopBar leftBurger={true} product={'stocknerve'} clientId={[
                { label: '自用樣板', value: 'daddy960' },
                { label: '導航樣板', value: 'daddy960_hang' },
            ]}/>);
        templateProps.layout.Row2 = Daddy960_Footer;
        templateProps.layout.Drawer1 = (<Fr_WithDrawerCloseButton.Display right Button={<BsChevronRight css={css `
              #__html {
                position: relative;
                z-index: 100;
              }
              color: #ffff00;
              background-color: #56565644;
              border-radius: 5px;
              height: 64px;
              width: 28px;
              font-size: 24px;
            `}/>}>
        <Daddy960_stocknerve_SidePane2 />
      </Fr_WithDrawerCloseButton.Display>);
        templateProps.layout.Drawer2 = (<Fr_WithDrawerCloseButton.Display left Button={<BsChevronLeft css={css `
              #__html {
                position: relative;
                z-index: 100;
              }
              color: #ffff00;
              background-color: #56565644;
              border-radius: 5px;
              height: 64px;
              width: 28px;
              font-size: 24px;
            `}/>}>
        <Daddy960_stocknerve_SidePane3 />
      </Fr_WithDrawerCloseButton.Display>);
        templateProps.layout.login = (<Daddy960LoginView product='stocknerve' faviconUrl='stocknerve/favicon.png' lineLink='https://page.line.me/?accountId=730hvfcs'/>);
        templateProps.hooks.add(fr_me.useInstall);
        templateProps.hooks.add(useSignalrStart2_0);
    },
};
